import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"
import { ParallaxProvider } from "react-scroll-parallax"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider"
import socialImage from "../../static/social-share/DMITRIEVA-ART-social-share.jpg"
import SEO from "../components/seo"
import News from "../components/News"

var arraySort = require("array-sort")

const NewsPage = ({ data, location, pageContext }) => {
  const content = data.allPrismicNews.edges
  const contentOrdered = arraySort(content, "node.data.news_date", {
    reverse: true,
  })
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)
  const toggleTransition = state.toggleTransition
  const path = location.pathname
  const langSliced = path.slice(1, 3)
  useEffect(() => {
    if (toggleTransition) {
      setTimeout(() => {
        dispatch({ type: "TOGGLE_TRANSITION" })
      }, 600)
    }
  }, [])
  return (
    <>
      <ParallaxProvider>
        <SEO
          title={
            langSliced === "fr"
              ? "Actualités"
              : langSliced === "ru"
              ? "Новости"
              : "News"
          }
          image={socialImage}
          lang={langSliced}
          path={path}
        />
        <News content={content} location={location} pageContext={pageContext} />
      </ParallaxProvider>
    </>
  )
}

export default NewsPage

export const query = graphql`
  query NewsPage($lang: String!) {
    allPrismicNews(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          id
          data {
            news_date
            media_name
            news_title
            news_link
            news_images {
              news_image {
                fluid {
                  src
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
