import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import Img from "gatsby-image"

import "./styles.scss"

const PicturesGrid = ({ pictures }) => {
  return (
    <div className="newsPictures">
      <ul className="newsPictures-grid">
        {pictures.map(item => (
          <li key={item.news_image.fluid.src}>
            <ScrollAnimation
              animateIn="slideIn"
              duration={1}
              delay={100}
              animateOnce
            >
              <Img fluid={{ ...item.news_image.fluid, aspectRatio: 1 }} />
            </ScrollAnimation>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default PicturesGrid
