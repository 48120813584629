import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import Img from "gatsby-image"

import PicturesGrid from "./PicturesGrid"
import "./styles.scss"

const News = ({ content, location, pageContext }) => {
  const langSliced = location.pathname.slice(1, 3)

  return (
    <section className="news">
      <div className="mobile-header" />
      <h1 className="news-title">
        {langSliced === "fr"
          ? "Actualités"
          : langSliced === "ru"
          ? "Новости"
          : "News"}
      </h1>

      <ul className="news-list">
        {content.map(news => {
          return (
            <li key={news.node.id}>
              <ScrollAnimation
                animateIn="slideIn"
                duration={1}
                delay={100}
                animateOnce
              >
                <article className="news-list-item">
                  <time dateTime={news.node.data.news_date}>
                    {news.node.data.news_date}
                  </time>
                  {news.node.data.media_name && (
                    <p>{news.node.data.media_name}</p>
                  )}
                  {news.node.data.news_title && (
                    <h2>{news.node.data.news_title}</h2>
                  )}
                  {news.node.data.news_link && (
                    <a
                      href={news.node.data.news_link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {langSliced === "fr"
                        ? "Lire l'article"
                        : langSliced === "ru"
                        ? "Читать статью"
                        : "Read the article"}
                    </a>
                  )}
                  {news.node.data.news_images[0].news_image.fluid !== null && (
                    <PicturesGrid pictures={news.node.data.news_images} />
                  )}
                </article>
              </ScrollAnimation>
            </li>
          )
        })}
      </ul>
    </section>
  )
}

export default News
